.purchase_list {
    padding: 3.125rem;
    gap: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1440px;
    margin: auto;
}

.purchase-list-header-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    gap: 0.75rem;
}

.purchase-list-header-wrap h3 {
    color: #183153;
    text-align: center;
    font-weight: 600;
    font-size: 3rem;
    line-height: 1.1;
}

.purchase-list-header-wrap p {
    color: #183153;
    text-align: center;
    font-weight: 400;
    font-size: 1.25rem;
}

.purchase-list-container-wrap {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.purchase_list_container {
    background: #fff;
    border: 2px solid #ececed;
    border-radius: 1rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    width: 22rem;
    cursor: pointer;
}

.purchase_list_header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.purchase_list_header h2 {
  font-weight: 600;
  line-height: 1.3;
  font-size: 1.75rem;
}

.purchase_list_header p{
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: baseline;
}

.purchase_list_line {
  outline: 1px solid #ececed;
  width: 100%;
}

.purchase_lists {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;
}

.purchase_lists li{
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
  gap: 1rem;
}

.purchase_lists i {
  color: #183153;
}

.purchase_list_cta {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.purchase_list_cta button {
    border: 2px solid #ececed;
    border-radius: 0.4375rem;
    background: #fff;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 3.75rem;
    color: #202046;
    font-weight: 500;
    font-size: 1.1rem;
    cursor: pointer;
}

.purchase_list_container:hover{
    background: #004988;
    color: #fff !important;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
}

.purchase_list_container:hover .fa-solid {
    color: #fff;
    transition: all 0.25s ease-in-out;
}

@media screen and (max-width: 960px) {
    .purchase-list-header-wrap {
        width: 100%;
    }

    .purchase-list-header-wrap h3 {
        font-size: 2rem;
        line-height: 2.25rem;
    }

    .purchase-list-header-wrap p {
        font-size: 1rem;
    }

    .purchase_list {
        gap: 1.5rem;
        padding: 1.563rem;
    }

    .purchase-list-container-wrap {
        flex-direction: column;
        gap: 1.5rem;
    }

    .purchase_list_container {
        width: unset;
    }
}