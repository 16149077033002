.notfound-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.notfound-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notfound-content h2 {
    font-size: 15rem;
    font-weight: 600;
    color: #183153;
    line-height: 1;
}

.notfound-content p {
    font-size: 2rem;
    color: #183153;
}

.notfound-content button {
    margin-top: 2rem;
    border: none;
    padding: 1rem 2rem;
    border-radius: 0.35rem;
    background: #183153;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;
}