.support-form-content {
    width: 100%;
}

.support-form-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}

.support-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
}

.support-form input {
    width: 80%;
    padding: 0.938rem;
    border-radius: 0.5rem;
    background: #fff;
    border: 1px solid #e6dcee;
    font-size: 1rem;
    font-weight: 400;
}

.support-form input:focus {
    outline: 1px solid #333;
}

.support-form button {
    width: 20%;
    border-radius: 0.5rem;
    background: #183153;
    border: none;
    margin: 0;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.938rem;
}

@media screen and (max-width: 960px) {
    .support-form-content {
        padding-top: 1rem;
    }
    .support-form input {
        width: 100%;
    }
    .support-form button {
        width: 40%;
    }
}