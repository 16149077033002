.hero-container {
    max-width: 1440px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: auto;
    padding: 80px 0 0 3.125rem;
    height: 100%;
}

.hero-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    padding: 0 3rem 0 0;
}

.hero-content h1 {
    font-size: 3.75rem;
    line-height: 4.5rem;
    font-weight: 700;
    color: #183153;
}

.hero-content p {
    font-size: 1.2rem;
    line-height: normal;
    font-weight: 400;
    color: #183153;
}

.hero-cta {
    padding: 1rem 2rem;
    border: none;
    border-radius: 0.35rem;
    background: #183153;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.hero-cta:hover {
    background: #004988;
}

.hero-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-img img {
    display: flex;
    width: 100%;
}

@media screen and (max-width: 960px) {
    .hero-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8rem 1.563rem 0;
    }

    .hero-content {
        gap: 1rem;
        padding: 0;
        padding-top: 2rem;
        align-items: center;
        justify-content: center;
    }

    .hero-content h1 {
        text-align: center;
        font-size: 2.85rem;
        line-height: 3rem;
    }

    .hero-content p {
        text-align: center;
        font-size: 1.1rem;
        line-height: normal;
    }

    .hero-cta {
        align-self: center;
        justify-self: center;
        margin-top: 1rem;
    }
}